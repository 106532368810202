import React from 'react';
import '../App.css';

function Galerie() {
  return (
    <>      
      <div className="content">   
        <div className="galerie-container">  
          <img src={require("./img/lightVersions/lespaul400x604.jpg").default} alt=""></img>  
          <img src={require("./img/lightVersions/peavey400x286.jpg").default} alt=""></img>
          <img src={require("./img/lightVersions/drum400x225.jpg").default} alt=""></img>
          <img src={require("./img/lightVersions/EHC400x266.jpg").default} alt=""></img>
          <img src={require("./img/lightVersions/beer200x356.jpg").default} alt=""></img>
          <img src={require("./img/lightVersions/EHC400x545.jpg").default} alt=""></img>
          <img src={require("./img/lightVersions/line6400x604.jpg").default} alt=""></img>
          <img src={require("./img/lightVersions/EHC500x600.jpg").default} alt=""></img>
          <img src={require("./img/lightVersions/cigar400x400.jpg").default} alt=""></img>
          <img src={require("./img/lightVersions/mic400x533.jpg").default} alt=""></img>
          <img src={require("./img/lightVersions/EHC800x454.jpg").default} alt=""></img>
          <img src={require("./img/lightVersions/drum400x604.jpg").default} alt=""></img>
          <img src={require("./img/lightVersions/skull400x604.jpg").default} alt=""></img>                
        </div>                
      </div>
    </>
  );
}

export default Galerie;