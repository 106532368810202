import React from 'react';

function Footer() {
  return (
    <>     
      <div className="footer">
        <br/><br/>

			&copy; bluesrookie 2021
			</div>
    </>    
  );
}

export default Footer;