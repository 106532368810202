import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Band from './pages/Band';
import Auftritte from './pages/Auftritte';
import Galerie from './pages/Galerie';
import Songliste from './pages/Songliste';
import Kontakt from './pages/Kontakt';
import Logo from './pages/Logo';
import Socialmedia from './pages/Socialmedia';
import Footer from './pages/Footer';

function App() {
  return (
    <>  
      <div className="wrapper">
        <Router>          
          <Navbar/>
          <Logo />               
          <Switch>          
            <Route path='/' exact component={Home} />
            <Route path='/Auftritte' component={Auftritte} />
            <Route path='/Band' component={Band} />
            <Route path='/Galerie' component={Galerie} />
            <Route path='/Songliste' component={Songliste} />
            <Route path='/Kontakt' component={Kontakt} />
          </Switch> 
          <Socialmedia />     
          <Footer/>   
        </Router> 
      </div>
    </>
  );
}

export default App;