import React from 'react';
import '../App.css';

function Band() {
  return (
    <>
      <div className="content">

        giäc (vocals)<br/><br/>       
        cama (guitar)<br/><br/>       
        christoph (bass)<br/><br/>        
        hansj (keys)<br/><br/>        
        urs (drums)<br/><br/>    
        just (guitar,vocals)<br/><br/>    

      </div>
    </>    
  );
}

export default Band;