export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    cName: 'nav-text'
  },
  {
    title: 'Band',
    path: '/Band',
    cName: 'nav-text'
  },
  {
    title: 'Auftritte',
    path: '/Auftritte',
    cName: 'nav-text'
  },
  {
    title: 'Songliste',
    path: '/Songliste',
    cName: 'nav-text'
  },
  {
    title: 'Galerie',
    path: '/Galerie',
    cName: 'nav-text'
  },
  {
    title: 'Kontakt',
    path: '/Kontakt',
    cName: 'nav-text'
  }
];