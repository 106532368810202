import React from 'react';
import '../App.css';

function Kontakt() {
  return (
    <>      
      <div className="content">
        
        www.calandahurricanes.ch<br/> 
				chur, schweiz<br/><br/>  
        kontakt:<br/>
			
        <a 
            className="mail" 
            href="mailto:calandahurricanes@gmx.ch"            
          >calandahurricanes@gmx.ch
        </a>
   
      </div>
    </>
  );
}

export default Kontakt;