import React from 'react';
import '../App.css';

function Auftritte() {  
  

  return (
    <>      
      <div className="content">

      Bar Hotel Saratz, Pontresina, 30. Januar 2016<br/><br/>        
      Blockhütte Bonaduz, 4. November 2016<br/><br/>     
      Obere Au, Grümpi EHC Chur, 24. Juni 2017<br/><br/>       
      Bestzeit Party, Parpan, 29. März 2018<br/><br/>

      </div>
    </>    
  );
}

export default Auftritte;