import React from "react";
import '../App.css';

function Logo() {
  
  return (  

  <>     
      <svg 
        className="logo"
        max-width="60%" 
        height="60%" 
        // width="940"
        // height="550"

        viewBox="-50 0 1400 800" 
        // viewBox="0 0 440 248" 

        // preserveAspectRatio="xMidYMid slice"
        preserveAspectRatio="xMidYMid meet"
      >   



        <path 
            id="hurricanes-linie"
            d="M0 429.445 255.966 428.377 262.898 434.785 267.164 425.707 
            276.763 439.058 288.495 420.901 291.161 416.628 320.49 413.958 
            346.087 368.031 362.085 367.497 398.88 322.639 407.412 349.34 
            442.607 306.084 447.407 315.162 449.006 289.529 455.939 286.859 
            464.471 278.314 476.736 276.178 485.268 311.424 486.868 327.979 
            494.334 322.105 510.865 334.387 536.461 381.382 540.194 392.063 
            546.593 399.005 557.792 406.482 563.124 402.209 572.19 421.435 
            578.589 412.356 591.921 410.754 608.452 374.974 614.851 368.565 
            632.448 331.183 641.514 327.979 652.712 342.932 656.445 361.089 
            661.778 357.351 668.177 363.759 690.574 327.979 712.438 307.686 
            717.77 276.178 741.767 282.052 752.432 279.916 758.298 296.471 
            762.031 292.733 766.297 301.277 775.896 295.937 782.295 310.89 
            803.625 308.22 806.292 318.901 820.157 315.696 822.823 304.482 
            838.821 344.534 837.754 314.094 858.551 352.01 874.549 347.738 
            906.545 140 994 548 1013.2 639 1045.21 416 1281 417.067 " 
        />
    
        <defs>
          <filter id="blur1">
              <feGaussianBlur stdDeviation="5" />
          </filter>
        </defs>

      
        <circle 
          r="20" 
          fill="white" 
          // filter="url(#blur1)" 
          // opacity="1"
          >
        
          <animateMotion 
            dur="10s" 
            repeatCount="indefinite" 
            rotate="auto"
            path="M0 429.445 255.966 428.377 262.898 434.785 267.164 425.707 
              276.763 439.058 288.495 420.901 291.161 416.628 320.49 413.958 
              346.087 368.031 362.085 367.497 398.88 322.639 407.412 349.34 
              442.607 306.084 447.407 315.162 449.006 289.529 455.939 286.859 
              464.471 278.314 476.736 276.178 485.268 311.424 486.868 327.979 
              494.334 322.105 510.865 334.387 536.461 381.382 540.194 392.063 
              546.593 399.005 557.792 406.482 563.124 402.209 572.19 421.435 
              578.589 412.356 591.921 410.754 608.452 374.974 614.851 368.565 
              632.448 331.183 641.514 327.979 652.712 342.932 656.445 361.089 
              661.778 357.351 668.177 363.759 690.574 327.979 712.438 307.686 
              717.77 276.178 741.767 282.052 752.432 279.916 758.298 296.471 
              762.031 292.733 766.297 301.277 775.896 295.937 782.295 310.89 
              803.625 308.22 806.292 318.901 820.157 315.696 822.823 304.482 
              838.821 344.534 837.754 314.094 858.551 352.01 874.549 347.738 
              906.545 140 994 548 1013.2 639 1045.21 416 1281 417.067 ">
            </animateMotion>
        </circle> 

        <defs>
          <filter id="blur2">
              <feGaussianBlur stdDeviation="40" />
          </filter>
        </defs>

        <circle 
          r="300" 
          fill="white" 
          filter="url(#blur2)" 
          opacity=".6">
        
          <animateMotion 
            dur="10s" 
            repeatCount="indefinite" 
            rotate="auto"
            path="M0 429.445 255.966 428.377 262.898 434.785 267.164 425.707 
              276.763 439.058 288.495 420.901 291.161 416.628 320.49 413.958 
              346.087 368.031 362.085 367.497 398.88 322.639 407.412 349.34 
              442.607 306.084 447.407 315.162 449.006 289.529 455.939 286.859 
              464.471 278.314 476.736 276.178 485.268 311.424 486.868 327.979 
              494.334 322.105 510.865 334.387 536.461 381.382 540.194 392.063 
              546.593 399.005 557.792 406.482 563.124 402.209 572.19 421.435 
              578.589 412.356 591.921 410.754 608.452 374.974 614.851 368.565 
              632.448 331.183 641.514 327.979 652.712 342.932 656.445 361.089 
              661.778 357.351 668.177 363.759 690.574 327.979 712.438 307.686 
              717.77 276.178 741.767 282.052 752.432 279.916 758.298 296.471 
              762.031 292.733 766.297 301.277 775.896 295.937 782.295 310.89 
              803.625 308.22 806.292 318.901 820.157 315.696 822.823 304.482 
              838.821 344.534 837.754 314.094 858.551 352.01 874.549 347.738 
              906.545 140 994 548 1013.2 639 1045.21 416 1281 417.067">
            </animateMotion>
        </circle>   

        <text 
          className="font-finder" 
          x="300px"
          y="230px"
        >Calanda
        </text>    

        <text 
          className="font-finder" 
          x="380px"
          y="520px"
        > Hurricanes
        </text>      


      </svg>      
  </> 
   
  );
}

export default Logo;