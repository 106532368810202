import React from 'react';

function Socialmedia() {
  return (
    <>     
      <div className="socialmedia">

        <div class="footer-social-icons">
        <br/><br/>
        <h4 class="_14">follow us on</h4>
        <ul class="social-icons">
            <li><a href="https://www.facebook.com/calandahurricanes/" class="social-icon"> <i class="fa fa-facebook"></i></a></li>
            <li><a href="https://instagram.com/calandahurricanes?igshid=7bki30sjp2j" class="social-icon"> <i class="fa fa-instagram"></i></a></li>
            {/* <li><a href="" class="social-icon"> <i class="fa fa-twitter"></i></a></li>
            <li><a href="" class="social-icon"> <i class="fa fa-rss"></i></a></li>
            <li><a href="" class="social-icon"> <i class="fa fa-youtube"></i></a></li>
            <li><a href="" class="social-icon"> <i class="fa fa-linkedin"></i></a></li>
            <li><a href="" class="social-icon"> <i class="fa fa-github"></i></a></li> */}
        </ul>
        </div>
			
			</div>
    </>    
  );
}

export default Socialmedia;