import React from 'react';
import '../App.css';

function Songliste() {
  return (
    <>      
      <div className="content">
        
        3DoorsDown - Kryptonite<br/><br/>  
        77 Bombay Street - I love Lady Gaga<br/><br/>  
        Deep Purple - Smoke on the Water<br/><br/>  
        Good Charlotte - Clique<br/><br/>  
        Green Day - Boulevard of Broken Dreams<br/><br/>  
        Judas Priest - Breaking the Law<br/><br/>  
        Neil Young - Rockin' in the Free World<br/><br/>  
        Scorpions - Rock you like a Hurricane<br/><br/>  
        Status Quo - Rockin' all over the World<br/><br/>  
        The Beatles - Rock'n'Roll Music<br/><br/>  
        The Black Keys - Lonely Boy<br/><br/>  
        Tom Petty - Yer so Bad<br/><br/>  
        U2 - One<br/><br/>  
        UFO - Doctor Doctor<br/><br/>  
        ZZ Top - Sharp Dressed Man<br/><br/>  
        ZZ Top - Stages<br/><br/>  
        ZZ Top - Tush<br/><br/>  
      
      </div>
    </>
  );
}

export default Songliste;