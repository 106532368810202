import React from 'react';

function Home() {
  return (
    <>      
      <div className="content">
      <img src={require("./img/bestzeit01.jpg").default} alt=""></img><br/><br/>
        <h1>Rock you like a Hurricane!</h1><br/><br/>
        <p>Willkommen bei den Calanda Hurricanes. 
          Wir sind eine Coverband aus Graubünden.
          Zu unserem Repertoire gehören Songs von 
          ZZ Top, Deep Purple, Status Quo, 3DoorsDown u.v.a. </p><br/>
        <p>Wenn Ihr uns für Eure Parties, 
        Feste, Open Airs und andere Auftritte buchen wollt, schickt uns 
        ein Mail. </p>              
      </div>
    </>
  );
}

export default Home;